import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Container,
  Button,
} from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockParallax from "../blockParallax"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"

const ImageAndText = ({ block }) => {
  const { image } = block.content || block

  return (
    <Grid
      columns={[1, 1, "1fr 1fr"]}
      gap={[2, 2, 3, 10]}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {image && image.gatsbyImageData && (
        <Box
          sx={{
            width: ["100%", "100%"],

            height: "100%",

            ".gatsby-image-wrapper": {
              height: "100%",
            },
            overflow: "hidden",
          }}
        >
          <GatsbyImage image={image.gatsbyImageData} alt="" />
        </Box>
      )}
      <Container>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              p: {
                fontSize: ["50px"],
                fontWeight: 500,
                mt: [0],
              },
            }}
            dangerouslySetInnerHTML={{ __html: block.body }}
          />
          {block.page && block.page.link && (
            <InboundLink to={getPagePath(block.page.link, block.page.locale)}>
              {" "}
              <Button
                sx={{
                  borderRadius: "4px",
                  px: 4,
                  py: 2,
                  fontSize: "18px",
                  width: "160px",
                  height: "56px",
                }}
              >
                {" "}
                Contattaci
              </Button>
            </InboundLink>
          )}
        </Flex>
      </Container>
    </Grid>
  )
}

export default ImageAndText
