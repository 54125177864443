import React, { useRef, useState, useLayoutEffect, useEffect } from "react"
import { Box, Grid, Hea, ding, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const ParallaxText = ({ children, reverse = false }) => {
  const isBrowser = typeof window !== "undefined"
  const parallaxRef = useRef()
  const scrollerRef = useRef()
  const textRef = useRef()

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const section = parallaxRef.current
      const w = scrollerRef.current
      const [x, xEnd] = !reverse
        ? ["80%", (w.scrollWidth - section.offsetWidth) * -1]
        : [w.scrollWidth * -1, "20%"]
      gsap.fromTo(
        w,
        { x },
        {
          x: xEnd,
          scrollTrigger: {
            trigger: section,
            scrub: 0.2,
          },
        }
      )
    }, parallaxRef.current)
  }, [])

  return (
    <Box
      ref={parallaxRef}
      className="parallax"
      sx={{
        h2: {
          color: "dark",
          fontWeight: "400",

          letterSpacing: ["2.875px", "2.875px", "-4.875px"],
          fontFamily: "acumin-pro-wide",

          my: [1, 1],
        },
        fontSize: "calc((50vw) / 9)",
      }}
    >
      <Box ref={scrollerRef} className="scroller">
        <h2 ref={textRef}>{children} </h2>
      </Box>
    </Box>
  )
}

const BigTitle = ({ block }) => {
  const { firstTitle, secondTitle } = block
  return (
    <Box sx={{ py: [4, 7] }}>
      <ParallaxText>{firstTitle}</ParallaxText>
      <ParallaxText reverse={true}>{secondTitle}</ParallaxText>
    </Box>
  )
}

export default BigTitle
