import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { MagicLink } from "../../utils/magicLink"
import BlockParallax from "../blockParallax"

const BigImageAndText = ({ block }) => {
  const { title, body, label, subtitle, images, link } = block.content
  return (
    <Box sx={{ position: "relative", backgroundColor: "dark" }}>
      <Box
        sx={{
          position: ["relative", "relative", "relative"],
          width: ["100%", "100%", "100%"],
          height: "auto",
          maxHeight: "630px",
          overflow: "hidden",
          ".gatsby-image-wrapper": {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <BlockParallax
          style={{ width: "100%", height: "130%" }}
          translateY={[-20, 20]}
        >
          <GatsbyImage image={images[0].gatsbyImageData} alt="" />
        </BlockParallax>
      </Box>
      <Container sx={{}}>
        <Grid
          columns={[1, 1, 2]}
          gap={[4, 4, 9]}
          sx={{ justifyItems: "start", py: 7 }}
        >
          <Box>
            <Heading
              as="h2"
              variant="h5"
              sx={{
                width: "80%",
                lineHeight: "1",
                color: "light",
                fontSize: [6, "72px"],
                fontWeight: "400",
                mt: [0],
              }}
            >
              {title}
            </Heading>
          </Box>
          <Box
            sx={{
              color: "light",
              fontSize: "18px ",
              fontWeight: 400,
            }}
          >
            <RichContentStructuredText text={body} theme={"dark"} />
            <Box
              sx={{
                mt: [3],
                a: {
                  fontWeight: "bold",
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "none",
                  },
                },
              }}
            >
              <MagicLink item={link}>{link}</MagicLink>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default BigImageAndText
